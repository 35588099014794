export default `Jim Fancer,Dogg Father,Paige Drobny,26,Nicolas Petit,7,Sydnie Bahl,8,5:21:00 AM
Mara Barack,Elon and the Dog(es) run amok,Jessie Holmes,15,Gabe Dunham,20,Emily Ford,34,6:30:00 AM
Rick DeMedeiros,Red Rocket Express,Matt Hall,4,Michelle Phillips,14,Emily Ford,34,5:00:00 AM
Chris Cobb,The Mushkateers,Jessie Holmes,15,Matthew Failor,31,Quince Mountain,27,1:45:00 AM
Ellen Evans,Mushing my way out of here,Mille Porsild,18,Lauro Eklund,23,Bryce Mumford,24,10:23:00 AM
Laurie Gray,Tails from the bark side,Mille Porsild,18,Lauro Eklund,23,Sydnie Bahl,8,2:14:00 AM
Mark Fore,Meet me at Front and Bering,Matt Hall,4,Nicolas Petit,7,Jenny Roddewig,10,10:30:00 AM
Craig Marmon,Soil Search ,Travis Beals,6,Bailey Vitello,22,Keaton Loebrich,13,1:14:00 AM
Jelena Fordella,Hill Sprint Beast Mode,Paige Drobny,26,Lauro Eklund,23,Bryce Mumford,24,11:11:00 PM
Kelly Tabler,Pink Puppy Club,Mille Porsild,18,Lauro Eklund,23,Sydnie Bahl,8,2:35:00 PM
Jarrod Carpenter,Chat Gee PT,Jessie Holmes,15,Nicolas Petit,7,Charmayne Morrison,30,4:32:00 AM
Chazz Hall,Puppy Love at First Sight,Paige Drobny,26,Anna Berington,12,Jenny Roddewig,10,11:23:00 AM
Michael McLaughlin,Only Mushers In The Building,Travis Beals,6,Michelle Phillips,14,Connor McMahon,28,3:13:00 AM
Brian O’Connor,Edward’s Snow Den,Jessie Holmes,15,Lauro Eklund,23,Bryce Mumford,24,11:26:00 AM
Aaron Berk,It's all about the Dawgs!,Matt Hall,4,Riley Dyche,17,Sydnie Bahl,8,2:30:00 PM
David Vanderpoel,Canineball Run,Ryan Redington,16,Matthew Failor,31,Emily Ford,34,6:09:00 PM
Doug White,Corgi's for life!,Matt Hall,4,Gabe Dunham,20,Calvin Daugherty,25,1:00:00 AM
Amy Atteberry,Indiana Bones and the Trail of Doom,Matt Hall,4,Nicolas Petit,7,Samantha LaLonde,3,9:15:00 AM
Rob Collier,Sled Zeppelin I,Jessie Holmes,15,Michelle Phillips,14,Brenda Mackey,9,2:23:00 PM
Blyden Nchako,The Hounds of Atlanta,Matt Hall,4,Michelle Phillips,14,Charmayne Morrison,30,11:11:00 AM
Jeremy Lizza,Dogs of Glacial Expeditions,Matt Hall,4,Bailey Vitello,22,Sydnie Bahl,8,11:10:00 PM
Reid Carter,The IditaReid Howlers,Paige Drobny,26,Jason Mackey,2,Emily Ford,34,6:44:00 AM
Pedro Soto,Hello Barkness My Old Friendd=,Jessie Holmes,15,Lauro Eklund,23,Charmayne Morrison,30,2:30:00 AM
Sarah Duffy,Underwoof Hills,Matt Hall,4,Bailey Vitello,22,Sydnie Bahl,8,4:17:00 AM
Jennifer Nichols,The Fast and the Flurrious,Jessie Holmes,15,Jason Mackey,2,Quince Mountain,27,5:22:00 AM
Douglas Borenstein,Doggie Fresh,Matt Hall,4,Jason Mackey,2,Charmayne Morrison,30,4:11:00 AM
Jane Guthrie,Bark the Casbah,Mille Porsild,18,Riley Dyche,17,Brenda Mackey,9,1:11:00 AM
Michelle Routh,Mush like a Girl,Travis Beals,6,Bailey Vitello,22,Brenda Mackey,9,2:00:00 PM
Chad Orwig,Old dog or new Mitch?,Mitch Seavey,32,Lauro Eklund,23,Keaton Loebrich,13,4:35:00 AM
Christian Nash,Give Your Sleighs a Tug ,Paige Drobny,26,Michelle Phillips,14,Keaton Loebrich,13,7:55:00 AM
Kymberli Roberts,Live Long & Pawsper,Mille Porsild,18,Jason Mackey,2,Quince Mountain,27,6:13:00 AM
Elizabeth Otwell Holland,Do Not Eat the Yellow Snow,Mitch Seavey,32,Gabe Dunham,20,Mike Parker,11,2:23:00 AM
Geoff Holland,Bring Out Yer Sled!,Paige Drobny,26,Riley Dyche,17,Samantha LaLonde,3,3:41:00 AM
Tara Montano,Sled Zeppelin II,Jessie Holmes,15,Nicolas Petit,7,Sydnie Bahl,8,6:15:00 AM
Shaun Bank,Saquon's Barkleys,Matt Hall,4,Bailey Vitello,22,Bryce Mumford,24,9:03:00 AM
Stephen Dixon,They Not Like Mush,Mille Porsild,18,Anna Berington,12,Mike Parker,11,11:15:00 AM
Chris Maycock,DOGE Days of Winter,Matt Hall,4,Riley Dyche,17,Brenda Mackey,9,6:32:00 AM
Brian Sobel,Large Land Mammal,Jessie Holmes,15,Anna Berington,12,Ebbe Pedersen,5,1:00:00 AM
Charles Schreiner,Borealis Blaze Canine Crew ,Matt Hall,4,Bailey Vitello,22,Charmayne Morrison,30,4:58:00 PM
Andrea Goodman,Iditarod Squad,Paige Drobny,26,Gabe Dunham,20,Jenny Roddewig,10,7:19:00 PM
Adam Sheinkopf,Smells Like Wet Dawwwgg,Matt Hall,4,Bailey Vitello,22,Charmayne Morrison,30,10:10:00 AM
Rachel Lieberman,Paws and Effect,Jessie Holmes,15,Jason Mackey,2,Sydnie Bahl,8,2:22:00 PM
Alex Consunji,Pearl’s Pawsitively Perfect Pals,Ryan Redington,16,Gabe Dunham,20,Brenda Mackey,9,12:37:00 PM
Melissa DeMedeiros,Fairy Tail Friends,Mille Porsild,18,Bailey Vitello,22,Jenny Roddewig,10,1:55:00 AM
Zach Tribble,The T-RUFF-le with Tribbles,Ryan Redington,16,Lauro Eklund,23,Charmayne Morrison,30,2:21:00 PM
Ashley Vanderpoel,Murphy wears booties too,Paige Drobny,26,Bailey Vitello,22,Mike Parker,11,4:25:00 AM
John Mello,This Team is Snow Joke,Mille Porsild,18,Bailey Vitello,22,Calvin Daugherty,25,11:11:00 AM
Matt Payne,Beyonsleigh's Slay Ride,Mille Porsild,18,Bailey Vitello,22,Keaton Loebrich,13,4:17:00 AM
Robin Orwig,A Court of Puppers and Ice,Mille Porsild,18,Gabe Dunham,20,Jenny Roddewig,10,5:16:00 PM
Carrie Flaspohler,Drexel's Dawgs,Travis Beals,6,Matthew Failor,31,Ebbe Pedersen,5,5:17:00 AM
Todd Bolsen,Poppy’s Last Dance,Mitch Seavey,32,Michelle Phillips,14,Charmayne Morrison,30,10:54:00 AM
Barb Reilly,Blizzard Barbie’s Bark Brigade,Mille Porsild,18,Jason Mackey,2,Quince Mountain,27,10:25:00 AM
Lynn Norman,The Fast and The Furriest,Travis Beals,6,Lauro Eklund,23,Emily Ford,34,2:22:00 PM
Christian Wayne,They Not Like Pups,Matt Hall,4,Riley Dyche,17,Quince Mountain,27,12:00:00 PM
Christopher Maltusch,Arcticuno Express,Ryan Redington,16,Nicolas Petit,7,Sydnie Bahl,8,4:17:00 AM
Bill Aaron,Press Paws,Matt Hall,4,Nicolas Petit,7,Justin Olnes,29,10:00:00 AM
Lily Li,The Pupsicles,Mille Porsild,18,Jason Mackey,2,Calvin Daugherty,25,5:15:00 AM
Aaron Danley,Raiders of the Lost Bark,Matt Hall,4,Michelle Phillips,14,Justin Olnes,29,5:07:00 PM
Becky Leach,Oh My God Becky Look at that Mush,Travis Beals,6,Lauro Eklund,23,Brenda Mackey,9,4:35:00 AM
Mary King,King's Canines,Mille Porsild,18,Riley Dyche,17,Calvin Daugherty,25,4:12:00 PM
Laura Bolsen,Running with the Devil,Paige Drobny,26,Jason Mackey,2,Calvin Daugherty,25,5:18:00 PM
Gil Elliot and Suzanne Ito,Bark to the Future,Travis Beals,6,Riley Dyche,17,Mike Parker,11,2:15:00 AM
Grant Guthrie,<think>Naming a dogsled team can be a fun and creative process!</think>,Matt Hall,4,Lauro Eklund,23,Charmayne Morrison,30,12:15:00 PM
Beth Fore,Paws for Effect,Jessie Holmes,15,Lauro Eklund,23,Calvin Daugherty,25,6:50:00 AM
Joey Ruse,Olympups,Mille Porsild,18,Bailey Vitello,22,Emily Ford,34,5:36:00 PM
John Kane,Lazy Dogs,Matt Hall,4,Michelle Phillips,14,Charmayne Morrison,30,5:23:00 PM
Joel Levinson,Oops Iditarod again.,Ryan Redington,16,Lauro Eklund,23,Charmayne Morrison,30,1:10:00 PM
Nathan Whitmire,Mathematics Dawgs,Travis Beals,6,Bailey Vitello,22,Brenda Mackey,9,5:55:00 AM
Thomas Stubbs,Bitches Brew,Mille Porsild,18,Matthew Failor,31,Quince Mountain,27,12:34:00 AM
Michelle Page,Scruffy Mutts,Mitch Seavey,32,Lauro Eklund,23,Ebbe Pedersen,5,3:45:00 AM
Allison Kline,There’s Always Money in the Dog Sled,Paige Drobny,26,Bailey Vitello,22,Keaton Loebrich,13,5:07:00 PM
Alex Qatsha,Labrador Contrivers,Travis Beals,6,Gabe Dunham,20,Charmayne Morrison,30,1:27:00 AM
Hamilton Evans,Simply Optimistic Sledding - Hamilton's Easily Lovable Pups,Mille Porsild,18,Bailey Vitello,22,Ebbe Pedersen,5,8:12:00 AM
Robert Doan,Texas Mayhem Mushers,Ryan Redington,16,Bailey Vitello,22,Sydnie Bahl,8,3:28:00 AM
Anthony Luaders,Cat Chasers,Travis Beals,6,Lauro Eklund,23,Calvin Daugherty,25,4:47:00 AM
Jason Franklin ,Woof-tang Clan,Ryan Redington,16,Nicolas Petit,7,Samantha LaLonde,3,8:08:00 AM
Sherry Stenehjem,New England Snow Raiders,Ryan Redington,16,Matthew Failor,31,Brenda Mackey,9,10:59:00 AM
Stephanie Smith,The Hungry Huskies,Travis Beals,6,Michelle Phillips,14,Connor McMahon,28,4:50:00 AM
Dave Kuehling,Bob Lob Paw,Matt Hall,4,Riley Dyche,17,Emily Ford,34,5:17:00 AM
Joanne Mello,With Great Powder Comes Great Responsibility ,Ryan Redington,16,Riley Dyche,17,Jenny Roddewig,10,5:16:00 PM
Mahdi Albori,Red Lantern Champ,Ryan Redington,16,Lauro Eklund,23,Calvin Daugherty,25,12:00:00 PM
Mike Marshall,Mack Attack,Ryan Redington,16,Nicolas Petit,7,Justin Olnes,29,9:27:00 AM
Alison Lowry ,Kenny Winny ,Jessie Holmes,15,Bailey Vitello,22,Quince Mountain,27,9:00:00 AM
Elisa Warren,Curd Runners: Whey Ahead of the Pack!,Ryan Redington,16,Bailey Vitello,22,Jenny Roddewig,10,2:56:00 AM
Brent Carney,Captain Furpants,Paige Drobny,26,Jason Mackey,2,Sydnie Bahl,8,11:15:00 AM
Shelby Benko,The Mushy Bunch,Mille Porsild,18,Nicolas Petit,7,Jenny Roddewig,10,7:35:00 PM
Justin Seides,Hush Your Mush,Ryan Redington,16,Lauro Eklund,23,Justin Olnes,29,4:27:00 AM
Matt Loutzenhiser ,Sled Zeppelin and the Huskies of Love ,Ryan Redington,16,Matthew Failor,31,Mike Parker,11,4:17:00 AM
Justin Tennant,Yada Yada Mush,Paige Drobny,26,Nicolas Petit,7,Sydnie Bahl,8,9:48:00 PM
Jake Martin,I-Doodlerod or Bust ,Ryan Redington,16,Lauro Eklund,23,Sydnie Bahl,8,10:23:00 AM
Dan Okray,Someday Came,Jessie Holmes,15,Michelle Phillips,14,Justin Olnes,29,5:33:00 AM
Handly Cameron,Multimodal Malamutes,Paige Drobny,26,Michelle Phillips,14,Brenda Mackey,9,8:32:00 AM
Perry Gulledge,Icy Dead People,Matt Hall,4,Bailey Vitello,22,Charmayne Morrison,30,4:50:00 AM
Helena Chern,They not like Togo,Ryan Redington,16,Gabe Dunham,20,Justin Olnes,29,2:22:00 AM
Lee Jaffe,Call of Whiners ,Jessie Holmes,15,Jason Mackey,2,Quince Mountain,27,4:22:00 PM
Steven Carrington,Mush Rush,Travis Beals,6,Anna Berington,12,Quince Mountain,27,2:30:00 PM
Elizabeth Sheinkopf,Paw-sitive Vibes,Jessie Holmes,15,Anna Berington,12,Charmayne Morrison,30,8:12:00 PM
Davis Palmie,Pack to the Future,Travis Beals,6,Riley Dyche,17,Sydnie Bahl,8,9:47:00 AM
Allie Moberg,Ice Road Mushers,Ryan Redington,16,Lauro Eklund,23,Brenda Mackey,9,10:15:00 AM
Chad Thropp,You're a Blizzard Hairy,Matt Hall,4,Nicolas Petit,7,Emily Ford,34,4:23:00 AM
Bethany Riddle ,Nome-ward Hounds,Matt Hall,4,Lauro Eklund,23,Sydnie Bahl,8,8:09:00 AM
Ken Simpson,Karl Barks,Paige Drobny,26,Gabe Dunham,20,Samantha LaLonde,3,3:26:00 AM
Carl Newton,Snowshore,Mille Porsild,18,Bailey Vitello,22,Keaton Loebrich,13,7:54:00 AM
Greg Teitelbaum,Fragrant Meat,Paige Drobny,26,Riley Dyche,17,Samantha LaLonde,3,5:03:00 AM
Shaun McCarthy,Doggy Dog World: an Eggcorn,Mitch Seavey,32,Matthew Failor,31,Jenny Roddewig,10,4:35:00 AM
Tim Bausman,Lantern Lighters,Jessie Holmes,15,Jason Mackey,2,Samantha LaLonde,3,8:05:00 AM
Kim Morris,Paw-sitive Attitude,Matt Hall,4,Nicolas Petit,7,Quince Mountain,27,2:53:00 AM
Laura Beystehner,Globe Trotters,Jessie Holmes,15,Nicolas Petit,7,Sydnie Bahl,8,10:39:00 PM
Elizabeth Abad,Elon Mush,Ryan Redington,16,Lauro Eklund,23,Justin Olnes,29,1:22:00 AM
Ashley Nguyen,Mush Potatos,Ryan Redington,16,Riley Dyche,17,Brenda Mackey,9,3:20:00 PM
David Mokotoff,Vincent van Snow Gogh,Mitch Seavey,32,Bailey Vitello,22,Brenda Mackey,9,6:28:00 AM
Marc Taylor,Yogi Bear ,Mille Porsild,18,Nicolas Petit,7,Mike Parker,11,12:00:00 AM
Doug Schaefer,Tundra Busters,Paige Drobny,26,Lauro Eklund,23,Quince Mountain,27,10:42:00 AM
Brian Smith,Chardee Macmush,Ryan Redington,16,Riley Dyche,17,Mike Parker,11,1:11:00 AM
M. Hans Delly,Loup-Garou in ATL,Matt Hall,4,Nicolas Petit,7,Emily Ford,34,3:00:00 AM
Jessie Kane,Paw Patrol,Mille Porsild,18,Riley Dyche,17,Sydnie Bahl,8,8:15:00 AM
Susan Scarce,Go Dawgs!,Ryan Redington,16,Bailey Vitello,22,Sydnie Bahl,8,3:46:00 AM
Larry Anderson,South Dakota Sled Dogs (RIP),Mille Porsild,18,Jason Mackey,2,Justin Olnes,29,3:49:00 PM
Beau Williamson,Run Dog Run,Ryan Redington,16,Nicolas Petit,7,Justin Olnes,29,6:00:00 AM
Kirsten Naik,The Tater Trotters ,Mille Porsild,18,Matthew Failor,31,Sydnie Bahl,8,2:48:00 AM
Jeremy McCabe,There's no snow in Anchorage. ,Jessie Holmes,15,Matthew Failor,31,Mike Parker,11,1:27:00 AM
Paige Sento,Ketch us if you Kan,Travis Beals,6,Matthew Failor,31,Samantha LaLonde,3,4:12:00 PM
Kim Russell,Denali Dashers,Mille Porsild,18,Jason Mackey,2,Justin Olnes,29,4:35:00 AM
Pierre Russell,Frostbite Fury,Matt Hall,4,Michelle Phillips,14,Emily Ford,34,9:00:00 AM
Steve Hinman,Let Him Mush!,Mille Porsild,18,Lauro Eklund,23,Bryce Mumford,24,3:00:00 AM
Austin Assavavallop,Daags,Ryan Redington,16,Lauro Eklund,23,Calvin Daugherty,25,5:21:00 PM
Thomas Bowman,Paw Patrol: The Balto Edition,Paige Drobny,26,Lauro Eklund,23,Charmayne Morrison,30,3:41:00 AM
Benjamin Mokotoff,Gee Parmesan,Paige Drobny,26,Bailey Vitello,22,Dane Baker,21,12:01:00 AM
Cindy Mokotoff,Arf Vandelay,Jessie Holmes,15,Gabe Dunham,20,Charmayne Morrison,30,12:02:00 AM
Rich Coutee,Kenai Geta Wut Wut,Mille Porsild,18,Lauro Eklund,23,Emily Ford,34,1:27:00 AM
Harry Einstein,Mushed Opportunity,Jeff Deeter,33,Anna Berington,12,Daniel Klein,19,0:00:00 AM`;