import React, { useState, useEffect } from 'react';
import {List, Table } from 'antd';
import { HashLink as Link } from 'react-router-hash-link';
import selectionsPromise from '../selections';
import keyBy from 'lodash/fp/keyBy';
import { useStandings } from '../context';
import { buildImprovingRender, sortNumeric } from '../columnUtils';

const keyByBib = keyBy('bib');

const columns = [
    {
        title: 'Position',
        dataIndex: 'pos',
        key: 'pos',
        sorter: sortNumeric('pos'),
        render : buildImprovingRender({ sorter: sortNumeric('pos'), lowerIsBetter: true }),
        defaultSortOrder : 'ascend',
    },
    {
        title: 'Name',
        dataIndex: 'musher',
        key: 'musher',
        sorter: ({musher:a}, {musher:b}) => a.localeCompare(b),
        render : (text, {scratched}) => <span style={scratched ? { textDecoration: 'line-through'} : undefined}>{text}</span>
    },
    {
        title: 'Checkpoint',
        dataIndex: 'checkpoint',
        key: 'checkpoint',
        sorter: ({checkpoint:a}, {checkpoint:b}) => a.localeCompare(b)
    },
    {
        title: 'Tier',
        dataIndex: 'tier',
        key: 'tier',
        sorter: ({tier:a}, {tier:b}) => a.localeCompare(b),
        onFilter: (value, {tier}) => tier === value,
        filters: [
            { text: 'Challenger', value: 'Challenger'},
            { text: 'Veteran', value: 'Veteran'},
            { text: 'Rookie', value: 'Rookie'},
            { text: 'Unsponsored', value: 'Unsponsored'},
        ]
    },
    {
        title: 'Dogs In',
        dataIndex: 'dogsIn',
        key: 'dogsIn'
    },
    {
        title: 'Dogs Out',
        dataIndex: 'dogsOut',
        key: 'dogsOut'
    }
]

async function determineTiers(standings) {
    standings.forEach(s => {
        s.sponsors = [];
        s.tier = "Unsponsored";
    });
    const selections = await selectionsPromise;
    const standingsByBib = keyByBib(standings);

    selections.forEach(({ team, bibs }) => {
        const mushers = bibs
            .map(bib => standingsByBib[bib]);
        
        mushers.forEach((musher, i, arr) => {
            if(!musher) return;
            switch(i) {
                case 0:
                    musher.tier = 'Favorite';
                    break;
                case 1:
                    musher.tier = 'Contender';
                    break;
                case 2:
                    console.log(arr.length)
                    musher.tier = arr.length === 3 ? 'Rookie' : 'Veteran';
                    break;
                case 3:
                    musher.tier = 'Rookie';
                    break;
                default:
                    //do nothing
            }
        })

        mushers.forEach(s => s && s.sponsors.push(team));
    });

    standings.forEach(s => s.sponsors.sort((a,b) => a.localeCompare(b)))

    return standings;
}

function Standings() {
    const rawStandings = useStandings();
    const [standings, setStandings] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if(rawStandings === undefined) return;
        determineTiers(rawStandings)
            .then(setStandings)
            .then(() => setLoading(false));
    }, [rawStandings]);
    return <Table 
        dataSource={standings}
        locale={{emptyText: loading ? 'Loading' : 'Waiting On Standings from Iditarod.com'}}
        columns={columns}
        loading={loading}
        pagination={false}
        rowKey='bib'
        onRow={({bib}) => ({ id: bib })}
        expandedRowRender={({sponsors}) => (
            <List size="small">
                {sponsors.map((s,i) => <List.Item key={i.toString()}><Link to={`/#${s.replace(/\W/g, '-').toLowerCase()}`}>{s}</Link></List.Item> )}
            </List>
        )}/>;
}

export default Standings;